import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import HeroBanner from 'components/heroBanner'
import Intro from 'components/intro'
import CopyBlock from 'components/copyBlock'
import ComparisonTable from 'components/comparisonTable'
import PartnerCarousel from 'components/partnerCarousel'
import Awards from 'components/awards'
import { graphql } from 'gatsby'

const ITServicesPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.itservicesJson.title}</title>
      <meta name="description" content={data.itservicesJson.description} />
    </Helmet>
    <HeroBanner hero={data.itservicesJson.hero} highlightColor="purple" />
    <Intro intro={data.itservicesJson.intro} />
    <CopyBlock bgTint noPadding>
      <ComparisonTable tableData={data.itservicesJson.table} />
    </CopyBlock>
    <PartnerCarousel />
    <Awards />
  </Layout>
)

ITServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default ITServicesPage

export const query = graphql`
  query ITServicesPageQuery {
    itservicesJson {
      title
      description
      hero {
        heading
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      intro {
        heading
        copy
      }
      table {
        title
        columnHeaders
        records {
          feature
          description
          isInPackage
        }
      }
    }
  }
`
